import { Link } from "gatsby";
import React from "react"
import Layout from '../components/layout';

export default function VideoTemplate({ pageContext: { video } }) {
  return (
    <main>
        <title>VHS Transfers - {video.title}</title>
        <Layout>
            <div>
                <Link class="btn btn-primary my-3 ml-2" to="/">
                    Return Home
                </Link>
                <div class="px-sm-2 py-sm-5">
                    <div class="mx-auto d-sm-flex">
                        <div style={{ maxHeight: '700px' }} class="embed-responsive embed-responsive-4by3 mb-5">
                            <video
                                style={{ position: 'relative !important', maxHeight: '700px' }}
                                class="embed-responsive-item"
                                id={video.title}
                                controls
                                preload="metadata"
                            >
                                <source src={video.source.mp4} type="video/mp4" />
                                <source src={video.source.webm} type="video/webm" />
                            </video>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center flex-column mx-auto">
                        <h3>{video.title}</h3>
                    </div>
                    <div class="d-flex justify-content-center flex-column mx-auto" style={{ maxWidth: '55%' }}>
                        <img class="img-fluid" src={video.image.front} alt={video.title} />
                        <img class="img-fluid" src={video.image.side} alt={video.title} />
                    </div>
                </div>
                <Link class="btn btn-primary my-5 ml-2" to="/">
                    Return Home
                </Link>
            </div>
        </Layout>
    </main>
  );
}